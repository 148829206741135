import replace from 'lodash/replace';

export const getCompassUrl = (compassUrl = '') => {
  const hasPorts =
    window.location.host.includes('3000') ||
    window.location.host.includes('3001') ||
    window.location.host.includes('3002');

  let compassHost = replace(window.location.host, 'admin', 'compass');

  if (hasPorts) {
    compassHost = replace(compassHost, '3000', '4000');
    compassHost = replace(compassHost, '3001', '4000');
    compassHost = replace(compassHost, '3002', '4000');
  }

  const protocol = window.location.protocol;

  const newUrl = `${protocol}//${compassHost}${compassUrl}`;

  return newUrl;
};

export const redirectToCompass = (compassUrl) => {
  return window.location.replace(getCompassUrl(compassUrl));
};
