import React, { useEffect } from 'react';
import { Box, Container, Flex, Link, Text } from '@springcare/sh-component-library';
import { useRouter } from 'next/router';
import { PublicLayout } from './apps/Public/Public.layout';
import { getCompassUrl } from 'modules/shared/utils/redirectToCompass';

export const ErrorHandler = () => {
  const [countdown, setCountdown] = React.useState(7_000);

  const router = useRouter();
  const isCompassURL = router.asPath.includes('compass');
  const compassRoute = router.asPath.split('compass/')[1];
  const intendedCompassURL = getCompassUrl(`/${compassRoute}`);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1000);
    }, 1000);

    if (isCompassURL && countdown === 0) {
      clearTimeout(timerId);
      router.replace(intendedCompassURL);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isCompassURL, countdown]);

  return (
    <PublicLayout>
      <Container maxW="2xl">
        <Flex direction="column" gap={2}>
          <Text textStyle="heading-display">Oops!</Text>
          <Text textStyle="heading-large">We had trouble finding this page.</Text>
          {isCompassURL && (
            <Box>
              <Text textStyle="heading-small">
                It looks like you may be trying to access Compass. Please update your bookmarks!
              </Text>
              <Text textStyle="heading-small">
                You will be automatically redirected in {countdown / 1000} seconds or click&nbsp;
                <Link href={intendedCompassURL}>
                  <Text as="span" textStyle="heading-small">
                    here
                  </Text>
                </Link>
                &nbsp;to navigate right away.
              </Text>
            </Box>
          )}
        </Flex>
      </Container>
    </PublicLayout>
  );
};

ErrorHandler.displayName = 'ErrorHandler';

ErrorHandler.getLayout = (page) => <PublicLayout>{page}</PublicLayout>;

export default ErrorHandler;
